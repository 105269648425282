<template>
  <div>
    <div class="header_common_box" >
        <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div>
        <!-- <div style="padding-left: 20px;">
            当前类型：
            <van-radio-group v-model="type" icon-size="18px" direction="horizontal">
                <van-radio name="5">排列五</van-radio>
                <van-radio name="7">七星彩</van-radio>
            </van-radio-group>
        </div> -->
        
        <van-grid  direction="horizontal" :column-num="1">
            <van-grid-item v-for="(item,index) in masterList" :key="index" v-show="item.vipPrice!==null" @click="topay(item.kindId,item.relationProdidUserid,item.vipPrice)" >
                <p style="color:rgb(58, 138, 242);width:2rem;">{{ item.productName }}</p>
                <!-- <p>{{ item.personalProfile }}</p> -->
                &nbsp;
                <p style="color:red;margin: 0 1.8rem;width:2rem;">排列五/七星彩</p>
                &nbsp;
                &nbsp;
                <p style="width:2rem;">月亮：{{ item.vipPrice }}</p>
            </van-grid-item>
        </van-grid>

    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
export default {
    components:{
        vHeader
    },
    data(){
        return{
            title: this.$route.meta.title,
            masterList: [],
            pagination: {
                pageNo: 1,
                pageSize: 100,
            },
            userId: this.$store.state.common.loginInfo.userId,
            access_token: this.$store.state.common.loginInfo.access_token,

        }
    },
    computed:{
        // type:{
        //     get(){
        //         return this.$store.state.common.lotteryKind
        //     },
        //     set(val){
        //         this.$store.commit("common/updateState", [
        //             { name: ["lotteryKind"], value: val },
        //         ]);
        //     }
        // },
         

    },
    methods:{
        //对象数组去重
        unique(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.productName) && res.set(arr.productName, 1));
        },
        async getmasterlist(){
            let data = {
                kindId: 1,
                pageNo: this.pagination.pageNo,
                pageSize: this.pagination.pageSize,
                // sortBy: 'userId DESC',
                systemId: 100001, 
                }
                const res = await this.$HTTP.post(this, 'API_MOONMASTER_LIST', data, {}, true)
               
                if (res.statusCode === 200) {
                    console.log('所有大师信息：',res)
                    let arr1 = this.unique(res.data);
                    console.log('去重后所有大师信息：',arr1)

                    this.masterList=arr1
                    
                }
        },
        async topay(kindid,relatedid,price){
            this.$router.push({
                name:'commonvip',
                query:{
                    userid: this.userId,
                    price: price,
                    access_token: this.access_token,
                    mb_kind: kindid,
                    related_id: relatedid
                }
            })
        },
       
    },
    mounted(){
        if (this.$store.state.common.loginInfo.access_token===null || this.$store.state.common.loginInfo.access_token === '' || this.$store.state.common.loginInfo.access_token === undefined){
            this.$router.replace('/login')
        }
        this.getmasterlist()
    }
}
</script>

<style lang="less" scoped>
p{
   font-size: 0.3rem; 
}
</style>